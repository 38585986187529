import Link from "next/link";
import styled from "styled-components";
import { FILTERS_BACKGROUND } from "lib/theme/_colors";
import fonts from "lib/theme/_fonts";
import media from "lib/theme/media";
import Button from "components/Common/Button";
import brokenPencil from "assets/images/404-page-pencil.png";

export default function FourOhFour(props: { message?: string }) {
  return (
    <PageNotFoundWrapper>
      <ContentHolder>
        <img src={brokenPencil} alt="Broken Pencil" />
        <span>404</span>
        <p>{props.message || "Oops.. Something went wrong."}</p>
        <Link href="/">
          <Button primary as="a">
            Back Home
          </Button>
        </Link>
      </ContentHolder>
    </PageNotFoundWrapper>
  );
}

export const PageNotFoundWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${FILTERS_BACKGROUND};
  font-family: ${fonts.primary};
`;

export const ContentHolder = styled.div`
  width: 80%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > img {
    width: 85%;
  }

  & > span {
    font-size: 80px;
    font-weight: bold;

    @media ${media.medium} {
      font-size: 120px;
    }

    @media ${media.large} {
      font-size: 150px;
    }
  }

  p {
    font-size: 30px;
    margin-bottom: 25px;
  }
`;
